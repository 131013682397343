body {
  background-color: #1e1e1e;
  color: #ffffff;
  margin: 0;
  font-family: Arial, sans-serif;
}

button {
  background-color: #444;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

button:hover {
  background-color: #555;
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul li {
  padding: 10px;
  cursor: pointer;
}

nav ul li:hover {
  background-color: #333;
}

nav ul li.active {
  background-color: #555;
}
