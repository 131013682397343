.spaced {
  margin-top: 20px;
}

.discord-button {
  background-color: #7289da;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.discord-button:disabled {
  background-color: #aab8d4;
  cursor: not-allowed;
}

.settings-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.webhook-group {
  margin-top: 20px;
}

.raises-container {
  padding: 20px;
  background-color: #1a1a1a;
  color: #fff;
  border-radius: 8px;
  max-width: 1200px;
  margin: 0 auto;
}

.raises-header {
  margin-bottom: 20px;
}

.raises-header h2 {
  margin: 0;
  font-size: 24px;
}

.raises-header p {
  margin: 5px 0;
}

.raises-header button {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.raises-header button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.separator {
  margin: 20px 0;
  border: 1px solid #444;
}

.raises-config h3 {
  margin: 0 0 20px 0;
  font-size: 20px;
}

.checkbox-group,
.webhook-group,
.wallets-group {
  margin-bottom: 20px;
}

.checkbox-group label,
.webhook-group label,
.wallets-group label {
  display: block;
  margin-bottom: 10px;
}

.checkbox-group input,
.webhook-group input,
.wallets-group textarea {
  margin-right: 10px;
}

.webhook-group input,
.wallets-group textarea {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #333;
  color: #fff;
}

button {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.admin-grid {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.admin-section {
  flex: 1;
}

.divider {
  width: 1px;
  background-color: #444;
  margin: 0 20px;
}

.raises-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.header-left {
  flex: 1;
}

.coupon-container {
  flex: 1;
  text-align: right;
}

.coupon-input {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.coupon-input input {
  width: 200px; 
  margin-right: 10px;
}

.total-raffles-label {
  display: inline-block;
  padding: 8px;
  background-color: #333;
  color: #fff;
  border-radius: 5px;
}

.homepage-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #1a1a1a; 
  color: #fff; 
}

.homepage-container h2 {
  font-size: 3rem;
}

.subscription-info {
  margin-top: 20px; 
}

.subscription-action {
  display: flex;
  align-items: center;
  gap: 10px; 
  margin-top: 10px; 
}

.subscription-price {
  color: #ff4500; 
  font-weight: bold;
  animation: blink 1s infinite;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.valid-subscription {
  color: green;
}

.expired-subscription {
  color: red;
}

.sidebar .logo-container {
  text-align: center;
  padding: 1rem;
}

.sidebar .logo-container img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

/* Adicionadas classes para DiscordRoles */
.servers-list-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.add-server-button {
  background-color: #7289da;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  padding: 5px;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  text-align: center;
}

.servers-list {
  display: flex;
  gap: 10px;
  list-style: none;
  padding: 0;
  margin-top: 20px; /* Adicionada a margem superior */
}

.servers-list li {
  position: relative;
  cursor: pointer;
  padding: 0.5rem;
  border: 1px solid transparent;
  background: transparent;
  text-align: center;
  border-radius: 5px;
}

.servers-list li.selected {
  border: 2px solid gold;
}

.server-avatar {
  width: 50px;
  height: 50px;
  border-radius: 5px;
}

.server-avatar:hover::after {
  content: attr(data-server-name);
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translateX(-50%);
  background-color: #000;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
}

.roles-content {
  padding: 1rem;
}

.no-server-selected {
  text-align: center;
  padding: 2rem;
}

.roles-divider {
  margin-top: 20px;
  border-top: 1px solid #444;
}

.role-row {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
}

.input-field {
  width: 200px;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #333;
  color: #fff;
}

.select-field {
  width: 150px;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #333;
  color: #fff;
}

.save-role-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.remove-role-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.connect-wallet-button {
  background-color: #7289da;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  margin-right: 10px; 
}

.connect-wallet-button:disabled {
  background-color: #aab8d4;
  cursor: not-allowed;
}

.servers-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.servers-list {
  display: flex;
  gap: 2rem;
  list-style: none;
  padding: 0;
}

.servers-list li {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.server-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.delete-server-button {
  margin-top: 10px;
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.selected-server-name {
  text-align: center;
  font-size: 1.5rem;
  color: white;
  margin-top: 10px;
  margin-bottom: 10px;
}

.role-row .input-field.nft-collection-field {
  width: 425px;
}

.select-field-network {
  width: 100px;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #333;
  color: #fff;
}

.wallets-group {
  display: flex;
  gap: 10px;
  justify-content: flex-start; 
}

.wallet-adapter-button {
  background-color: #7289da !important;
  border: none !important;
  color: white !important;
  cursor: pointer !important;
  font-size: 16px !important;
  margin-right: 10px !important;
  margin-top: 20px !important;
  padding: 10px 20px !important;
  border-radius: 5px !important;
  height: 40px !important;
  font-weight: 400 !important;
}
.wallet-adapter-button-trigger {
  background-color: #7289da !important;
}

.wallet-adapter-modal-list .wallet-adapter-button {
  background-color: #10141f !important;
}

/* Add this CSS to your Style.css or equivalent */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
  margin-right: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

.role-row {
  display: grid;
  grid-template-columns: 1.5fr 3fr 1fr 1fr 1fr; /* Ajuste das proporções */
  gap: 20px; /* Aumenta o espaço entre os campos */
  align-items: center;
  margin-bottom: 10px;
}

.header-row {
  display: grid;
  grid-template-columns: 1.5fr 3fr 1fr 1fr 1fr; /* Mesma configuração da role-row */
  gap: 20px; /* Aumenta o espaço entre os campos no cabeçalho */
  align-items: center;
  background-color: #444;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
}

.header-cell {
  text-align: center;
  color: white;
}

.role-cell select,
.role-cell input {
  width: 100%;
  padding: 8px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #333;
  color: #fff;
}

.actions {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.quantity-field {
  width: 100%;
  padding: 8px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #333;
  color: #fff;
}